<template>
    <div class="loan-repayment-query">
      <h1>贷款还款查询</h1>
      <h3>公众号【王小义笔记】</h3>
      <form @submit.prevent="fetchLoanDetails">
        <div>
          <label for="loanAmount">贷款金额(万元):</label>
          <input type="text" id="loanAmount" v-model="loanAmount" required />
        </div>
        <div>
          <label for="calType">贷款方式:</label>
          <select id="calType" v-model="calType" required>
            <option value=1>等额本金</option>
            <option value=2>等额本息</option>
          </select>
        </div>
        <div>
          <label for="loanTerm">贷款期数:</label>
          <input type="number" id="loanTerm" v-model="loanTerm" required />
        </div>
        <div>
          <label for="annualInterestRate">贷款利率(%):</label>
          <input type="text" id="annualInterestRate" v-model="annualInterestRate" required />
        </div>
        <div>
          <label for="monthsPaid">已付期数:</label>
          <input type="number" id="monthsPaid" v-model="monthsPaid" required />
        </div>
        <button type="submit">查询</button>
        <!-- <button type="button" @click="clearForm">清除数据</button> -->
      </form>
      <div v-if="loanDetails">
        <h2>还款详情</h2> 
     
        <button @click="download()">浏览器打开下载表格</button>
  
        <p>总利息: {{ loanDetails.interestTotal }}      总还款: {{ loanDetails.repayTotal }}</p>
        <table>
          <thead>
            <tr>
              <th>期数</th>
              <th>利息</th>
              <th>还款</th>
              <th>总支付</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in loanDetails.info" :key="item.loanNumber">
              <td>{{ item.loanNumber }}</td>
              <td>{{ item.interest }}</td>
              <td>{{ item.repayment }}</td>
              <td>{{ item.totalPayment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import axios from 'axios';
  
  export default {


    setup() {
      const loanAmount = ref('');
      const calType = ref(1);
      const loanTerm = ref('');
      const annualInterestRate = ref('');
      const monthsPaid = ref('');
      const loanDetails = ref(null);

      const fetchLoanDetails = async () => {
        // 这里模拟一个API请求，实际应用中应替换为真实的API调用
        const response = await fetch('/api/getCal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            loanAmount: loanAmount.value,
            calType: calType.value,
            loanTerm: loanTerm.value,
            annualInterestRate: annualInterestRate.value,
            monthsPaid: monthsPaid.value
          })
        });
        const data = await response.json();
        loanDetails.value = data.data;
      };
  
      return {
        loanAmount,
        calType,
        loanTerm,
        annualInterestRate,
        monthsPaid,
        loanDetails,
        fetchLoanDetails
      };
    },
    
    // data() {
    //   return {
    //     loanAmount: '',
    //     calType: '1',
    //     loanTerm: '',
    //     annualInterestRate: '',
    //     monthsPaid: '',
    //     result: null
    //   };
    // },
    mounted() {
      // 获取URL中的参数
      const urlParams = new URLSearchParams(window.location.search);
      this.loanAmount = urlParams.get('loanAmount') || '';
      this.calType = urlParams.get('calType') || '1';
      this.loanTerm = urlParams.get('loanTerm') || '';
      this.annualInterestRate = urlParams.get('annualInterestRate') || '';
      this.monthsPaid = urlParams.get('monthsPaid') || '';
    },  


  methods: {
    
    async download() {
      const params = {
        // 这里是你需要传递的参数实体
        loanAmount: this.loanAmount,
        calType: this.calType,
        loanTerm: this.loanTerm,
        annualInterestRate: this.annualInterestRate,
        monthsPaid: this.monthsPaid
      };

      try {
        // 将参数对象转化为URL查询字符串
        const queryString = new URLSearchParams(params).toString();
        const url = `/api/downloadFile?${queryString}`;
        var ua = navigator.userAgent.toLowerCase();
        const weixinFlag = ua.match(/MicroMessenger/i)=="micromessenger";
        if(weixinFlag) {
            const tempUrl = url;
            history.pushState(null, null, tempUrl);
            alert('请点击右上角...用浏览器打开下载');
        } else {
        
          
          const response = await axios.get(url, {
            responseType: 'blob', // 重要：设置响应类型为blob
          });

          // 创建一个临时的URL对象，用于下载文件
          const blob = new Blob([response.data]);
          const tempUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          // 获取 Content-Disposition 头
          const contentDisposition = response.headers['content-disposition'];

          // 解析文件名
          let fileName = "还款明细表.xlsx"; // 默认文件名
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename\*?=['"]?([^;\r\n"]+)/);
            if (fileNameMatch && fileNameMatch[1]) {
              fileName = decodeURIComponent(fileNameMatch[1].replace(/['"]/g, ''));
            }
          }

          link.href = tempUrl;

          link.setAttribute('download', fileName); // 设置下载的文件名
          document.body.appendChild(link);
          link.click();
             // 移除临时链接元素
            document.body.removeChild(link);

            // 释放临时URL
            window.URL.revokeObjectURL(tempUrl);
        }

      } catch (error) {
        console.error('下载还款计划失败', error);
      }

      // 支持浏览器访问
      // try {
      //   const response = await axios.post('/daikuan/api/downloadFile', params, {
      //     responseType: 'blob', // 重要：设置响应类型为blob
      //   });

      //   // 创建一个临时的URL对象，用于下载文件
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement('a');
    
      //   // 获取 Content-Disposition 头
      //   const contentDisposition = response.headers.get('content-disposition');

      //   // // 解析文件名
      //   const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      //   const fileName = fileNameMatch ? fileNameMatch[1] : "还款明细表.xlsx";
      //   link.href = url;
      //   link.setAttribute('download', decodeURIComponent(fileName)); // 设置下载的文件名
      //   document.body.appendChild(link);
      //   link.click();
      // } catch (error) {
      //   console.error('下载还款计划失败', error);
      // }
    },
    // clearForm() {
    //   // 清空表单数据
    //   this.loanAmount = '';
    //   this.calType = '1';
    //   this.loanTerm = '';
    //   this.annualInterestRate = '';
    //   this.monthsPaid = '';
    //   this.result = null;
    // },


    // is_weixn(){
    //     var ua = navigator.userAgent.toLowerCase();
    //     if(ua.match(/MicroMessenger/i)=="micromessenger") {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    
    // changeUrl() {
    //   // 修改URL但不刷新页面
    //   history.pushState(null, null, url);

    //   // 调用微信JS-SDK接口
    //   wx.ready(() => {
    //     wx.onMenuShareTimeline({
    //       title: '分享标题',
    //       link: window.location.href,
    //       imgUrl: 'http://yourimage.com/image.jpg'
    //     });

    //     wx.onMenuShareAppMessage({
    //       title: '分享标题',
    //       desc: '分享描述',
    //       link: window.location.href,
    //       imgUrl: 'http://yourimage.com/image.jpg'
    //     });
    //   });
    // },
    // configureWeChatSDK() {
    //   // 这里你需要向你的服务器请求签名数据
    //   axios.get('/your/backend/api/wechat-signature', {
    //     params: {
    //       url: window.location.href.split('#')[0]
    //     }
    //   }).then(response => {
    //     const data = response.data;
    //     wx.config({
    //       debug: true,
    //       appId: data.appId,
    //       timestamp: data.timestamp,
    //       nonceStr: data.nonceStr,
    //       signature: data.signature,
    //       jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
    //     });
    //   }).catch(error => {
    //     console.error('微信JS-SDK配置失败', error);
    //   });
    // }
  }

};
  </script>
  
  <style>
  .loan-repayment-query {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  form div {
    margin-bottom: 10px;
  }
  
  label {
    display: inline-block;
    width: 120px;
  }
  
  input, select {
    width: 200px;
    padding: 5px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #4CAF50;
    color: white;
  }
  </style>
  